class OpenAI {
    constructor() {
        this.elements = {};
        this.whitespace = /[ \t]+/g;
    }

    async init() {
        this._setElements();
        if (!this.elements.userInfo) {
            this._createUserInfo();
        }
        this._observeResize();
        await this._loadChatConfigs();
        this.conversationsnapshot = JSON.parse(localStorage.getItem("conversationsnapshot"));
        if (!this.conversationsnapshot) {
            localStorage.setItem("conversationsnapshot", JSON.stringify([]));
            this.conversationsnapshot = [];
        }
        if (this.elements.appContent.childNodes.length === 0 || this.conversationsnapshot.length === 0) {
            this._initOpeningLine();
            this.sendMessage("", 0);
        }
    }

    async _loadChatConfigs() {
        const response = await fetch("/config/chat.config.json");
        const data = await response.json();
        this.configs = data.configs;
        this.characters = data.character;
        this.mention = new RegExp(`@(${this.characters.slice(1).flatMap(char => char.keywords).join("|")})\\b`, "gi");
        this.lastcharacter = this._initCharacter().short;
        this.intro = { role: "system", content: `${data.prologue} Current date is ${new Date(new Date().getTime() + 7 * 60 * 60 * 1000).toISOString().slice(0, 10)}.` };
        this.fisrtmessage = data.firstmessage;
    }

    async _CDNTrace() {
        const response = await fetch("https://neih.me/cdn-cgi/trace");
        const data = await response.text();
        const lines = data.split("\n");
        const getValue = (lines, key) => {
            const line = lines.find(line => line.includes(`${key}=`));
            return line.split("=")[1];
        }
        const [ConnectIP, CDNCoLo, CDNKex] = await Promise.all([
            getValue(lines, "ip"),
            getValue(lines, "colo"),
            getValue(lines, "kex")
        ]);
        const version = document.querySelectorAll(".version");
        version[0].innerHTML = `${CDNCoLo}${CDNKex}<br/>${ConnectIP}<br/>R0N13 v24.0918`;
    }

    _initCharacter() {
        return this.characters[Math.floor(Math.random() * (this.characters.length - 1)) + 1];
    }

    _initOpeningLine() {
        this.conversationsnapshot.push({ role: "system", content: this.fisrtmessage });
    }

    _setElements() {
        const createAndAddElement = (tag, classNames, parent) => {
            const element = document.createElement(tag);
            classNames.forEach(className => element.classList.add(className));
            parent.appendChild(element);
            return element;
        };
        const fragment = document.createDocumentFragment();
        this.elements.wrapper = createAndAddElement("main", ["main"], fragment);
        this.elements.header = createAndAddElement("header", ["header"], this.elements.wrapper);
        this.elements.version = createAndAddElement("span", ["version"], this.elements.header);
        this.elements.appContent = createAndAddElement("div", ["app-content"], this.elements.wrapper);
        this.elements.inputWrapper = createAndAddElement("div", ["input-wrapper"], this.elements.wrapper);
        this.elements.inputField = createAndAddElement("input", ["input"], this.elements.inputWrapper);
        document.body.appendChild(fragment);
    }

    _createUserInfo() {
        this._CDNTrace();
        this.elements.inputField.addEventListener("keyup", (event) => {
            if (event.key === "Enter") {
                this.inputHandle();
            }
            if (event.altKey && event.key === "r") {
                this.sendMessage(`>rs`, 1);
            }
            if (event.altKey && event.key === "c") {
                this.elements.inputField.value = `>cheat: `;
            }
        });
    }

    _observeResize() {
        const resizeObserverTop = new ResizeObserver(entries => {
            for (const entry of entries) {
                const { height } = entry.contentRect;
                this.elements.appContent.style.marginTop = `${height}px`;
            }
        });
        const resizeObserverBottom = new ResizeObserver(entries => {
            for (const entry of entries) {
                const { height } = entry.contentRect;
                const paddingBottom = height + 10;
                this.elements.appContent.style.marginBottom = `${paddingBottom}px`;
            }
        });
        resizeObserverTop.observe(this.elements.header);
        resizeObserverBottom.observe(this.elements.inputWrapper);
    }

    sanitizeString(input) {
        const tempDiv = document.createElement("div");
        return (tempDiv.innerText = input), DOMPurify.sanitize(tempDiv.innerText, {
            ALLOWED_TAGS: [],
            ALLOWED_ATTR: [],
            ALLOW_DATA_ATTR: false,
            ALLOW_UNKNOWN_PROTOCOLS: false
        });
    }

    inputHandle() {
        const playerinput = this.elements.inputField.value.trim();
        if (!playerinput) return;
        this.sendMessage(playerinput, 1);
    }

    parseMessage(str) {
        let purifiedinput = this.sanitizeString(str);
        if (/[a-zA-Z0-9]$/.test(purifiedinput)) {
            purifiedinput += ".";
        }
        this.characters.forEach(character => {
            if (character.keywords.some(keyword => purifiedinput.toLowerCase().includes(`@${keyword}`))) {
                this.lastcharacter = character.short;
            }
        });
        const nomention = purifiedinput.replace(this.mention, "$1");
        this.cheat = [];
        if (nomention.toLowerCase().startsWith("&gt;cheat:") || nomention.toLowerCase().startsWith(">cheat:")) {
            this.elements.inputField.value = "";
            const cheatText = nomention.substring(8);
            this.cheat = [{ role: "system", content: cheatText }];
            console.log(`cheat prompt:${cheatText}`);
        }
        else if (nomention.toLowerCase().startsWith("&gt;rs") || nomention.toLowerCase().startsWith(">rs")) {
            this.elements.appContent.innerHTML = "";
            this.elements.appContent.scrollTop = this.elements.appContent.scrollHeight;
            this.elements.inputField.value = "";
            this.conversationsnapshot = [];
            this._initOpeningLine();
        }
        else {
            const userMessage = document.createElement("p");
            userMessage.classList.add("bubble", "user");
            userMessage.textContent = nomention;
            this.elements.appContent.appendChild(userMessage);
            this.elements.appContent.scrollTop = this.elements.appContent.scrollHeight;
            this.elements.inputField.value = "";
            this.conversationsnapshot.push({ role: "user", content: nomention });
        }
    }

    async sendMessage(str, int) {
        if (int !== 0) {
            this.parseMessage(str);
        }
        const requestBody = this._buildRequestBody();
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(requestBody)
        };
        try {
            let retryCount = 0;
            let response;
            do {
                response = await fetch("https://openai.neih.workers.dev/v1/chat/completions", requestOptions);
                if (response.status === 429 || response.status === 401) {
                    retryCount++;
                } else {
                    break;
                }
            } while (retryCount < 10);
            const responsedata = await response.json();
            const responsetext = decodeURIComponent(responsedata.choices[0].message.content).replace(/^\n+(.*)/gm, "$1");
            const purified = this.sanitizeString(responsetext);
            const nomention = purified.replace(this.mention, "$1").replace("<br>", "\n");
            const recipientMessage = document.createElement("p");
            recipientMessage.classList.add("bubble", "recipient");
            this.characters.forEach((character) => {
                if (character.short === this.lastcharacter) {
                    const { name, status, avatar, color } = character;
                    recipientMessage.textContent = nomention;
                    recipientMessage.style.backgroundColor = color;
                    this.conversationsnapshot.push({ role: "assistant", content: nomention });
                    this.sectionsOnSnapshot();
                    localStorage.setItem("conversationsnapshot", JSON.stringify(this.conversationsnapshot));
                }
            });
            this.elements.appContent.appendChild(recipientMessage);
            this.elements.appContent.scrollTop = this.elements.appContent.scrollHeight;
        } catch (error) { }
    }

    _buildRequestBody() {
        return {
            model: this.configs.model,
            messages: [this.intro].concat(this.conversationsnapshot, this.cheat ? this.cheat : []),
            temperature: this.configs.temperature,
            max_tokens: this.configs.max_tokens,
            top_p: this.configs.top_p,
            frequency_penalty: this.configs.frequency_penalty,
            presence_penalty: this.configs.presence_penalty,
            stop: this.characters.map(character => `${character.short}: `)
        };
    }

    sectionsOnSnapshot() {
        if (this.conversationsnapshot.length > 100) {
            this.conversationsnapshot = this.conversationsnapshot.slice(this.conversationsnapshot.length - 100);
        }
    }
}
export { OpenAI };